/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-classes-per-file */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/*########### RC ##############*/ 

// Get RC
export const RC_GET = 'RC_GET';
export const RC_GET_SUCCESS = 'RC_GET_SUCCESS';
export const RC_GET_ERROR = 'RC_GET_ERROR';
// Get Rc Advanced
export const RC_ADVANCED_GET = 'RC_ADVANCED_GET';
export const RC_ADVANCED_GET_SUCCESS = 'RC_ADVANCED_GET_SUCCESS';
export const RC_ADVANCED_GET_ERROR = 'RC_ADVANCED_GET_ERROR';

export const RC_GET_SELECT = 'RC_GET_SELECT';
export const RC_SELECT = 'RC_SELECT';
export const RC_GET_WAN = 'RC_GET_WAN';
export const RC_GET_WAN_SUCCESS = 'RC_GET_WAN_SUCCESS';
export const RC_GET_WAN_ERROR = 'RC_GET_WAN_ERROR';

export const RC_POST_PORT_SETTING = 'RC_POST_PORT_SETTING';
export const RC_POST_PORT_SETTING_SUCCESS = 'RC_POST_PORT_SETTING_SUCCESS';
export const RC_POST_PORT_SETTING_ERROR = 'RC_POST_PORT_SETTING_ERROR';
export const GET_WAN_PASSPHRASE = 'GET_WAN_PASSPHRASE';
export const GET_WAN_PASSPHRASE_SUCCESS = 'GET_WAN_PASSPHRASE_SUCCESS';
export const GET_WAN_PASSPHRASE_ERROR = 'GET_WAN_PASSPHRASE_ERROR';
// Get wifi Scheduling
export const RC_GET_WIFI_SCHEDULING = 'RC_GET_WIFI_SCHEDULING';
export const RC_GET_WIFI_SCHEDULING_SUCCESS = 'RC_GET_WIFI_SCHEDULING_SUCCESS';
export const RC_GET_WIFI_SCHEDULING_ERROR = 'RC_GET_WIFI_SCHEDULING_ERROR';

export const VALIDATE_TWO_FACTOR_TOKEN = 'VALIDATE_TWO_FACTOR_TOKEN';
export const VALIDATE_TWO_FACTOR_TOKEN_SUCCESS = 'VALIDATE_TWO_FACTOR_TOKEN_SUCCESS';
export const VALIDATE_TWO_FACTOR_TOKEN_ERROR = 'VALIDATE_TWO_FACTOR_TOKEN_ERROR';

export const VALIDATE_RECOVERY_CODE = 'VALIDATE_RECOVERY_CODE';
export const VALIDATE_RECOVERY_CODE_SUCCESS = 'VALIDATE_RECOVERY_CODE_SUCCESS';
export const VALIDATE_RECOVERY_CODE_ERROR = 'VALIDATE_RECOVERY_CODE_ERROR';

export const RECOVERY_CODE_MODE = 'RECOVER_CODE_MODE';
export const TWO_FACTOR_TOKEN_MODE = 'TWO_FACTOR_TOKEN_MODE';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';

export * from './rc/actions';
