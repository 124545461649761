/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-classes-per-file */
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import rcSagas from './rc/saga';

export default function* rootSaga(getState) {
  yield all([authSagas(),rcSagas()]);
}
