/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-classes-per-file */
import {
    RC_GET,
    RC_GET_ERROR,
    RC_GET_SUCCESS,
    RC_SELECT,    
    RC_GET_SELECT,
    RC_GET_WAN,
    RC_GET_WAN_ERROR,
    RC_GET_WAN_SUCCESS,
    RC_POST_PORT_SETTING,    
    GET_WAN_PASSPHRASE,
    GET_WAN_PASSPHRASE_ERROR,
    GET_WAN_PASSPHRASE_SUCCESS,
    RC_ADVANCED_GET_ERROR,
    RC_ADVANCED_GET_SUCCESS,
    RC_ADVANCED_GET,
    RC_GET_WIFI_SCHEDULING,
    RC_GET_WIFI_SCHEDULING_ERROR,
    RC_GET_WIFI_SCHEDULING_SUCCESS
} from '../actions';

const INIT_STATE = {
    rc: null,
    wan:null,
    data:null,
    error: '',
    body : null,
    loading: false,
    rassphrase:null,
    advanced: null,
    wifiScheduling:null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RC_GET:
            return { ...state, loading: false };

        case RC_GET_SUCCESS:
            return {...state,loading: true,rc: action.payload,};

        case RC_GET_ERROR:
            return { ...state, loading: true, error: action.payload };
        // ADVANCED
        case RC_ADVANCED_GET:
            return { ...state, loading: false };

        case RC_ADVANCED_GET_SUCCESS:
            return {...state,loading: true,advanced: action.payload};

        case RC_ADVANCED_GET_ERROR:
            return { ...state, loading: true, error: action.payload };


        case RC_SELECT:
            return { ...state, loading: true, id: action.payload };

        case RC_GET_SELECT:
            return { ...state, loading: true, id: action.payload,rc: action.payload };

        case RC_GET_WAN:
            return { ...state, loading: false };

        case RC_GET_WAN_SUCCESS:
            return {...state,loading: true,wan: action.payload,};

        case RC_GET_WAN_ERROR:
            return { ...state, loading: true, error: action.payload };

        case RC_POST_PORT_SETTING:
            return { ...state, loading: true , data: action.payload};

        case GET_WAN_PASSPHRASE:
            return { ...state, loading: false, type: action.payload };

        case GET_WAN_PASSPHRASE_SUCCESS:
            return {...state,loading: true,rassphrase: action.payload};

        case GET_WAN_PASSPHRASE_ERROR:
            return { ...state, loading: true, error: action.payload };
        // Wifi Schedules
        case RC_GET_WIFI_SCHEDULING:
            return { ...state, loading: false };

        case RC_GET_WIFI_SCHEDULING_SUCCESS:
            return {...state,loading: true,wifiScheduling: action.payload,};

        case RC_GET_WIFI_SCHEDULING_ERROR:
            return { ...state, loading: true, error: action.payload };
        default:
            return { ...state };
    }
};
