/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-classes-per-file */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from '../../helpers/Firebase';
import { sha512 } from 'js-sha512';
import * as serviceWorker from '../../serviceWorker';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VALIDATE_TWO_FACTOR_TOKEN,
  VALIDATE_RECOVERY_CODE,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  validateTwoFactorTokenError,
  validateTwoFactorTokenSuccess,
  validateRecoveryCodeError,
  validateRecoveryCodeSuccess,
} from './actions';


import { adminRoot, currentUser, servicePath, ATOKEN, STOKEN ,pushWoosh} from "../../constants/defaultValues"
import { setCurrentUser, setTempCurrentUser, setRCs,getHeader,getArrayData,roqosData ,setroqosDataLocal} from '../../helpers/Utils';


export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watch2FAUser() {
  yield takeEvery(VALIDATE_TWO_FACTOR_TOKEN, validate2FAToken);
  yield takeEvery(VALIDATE_RECOVERY_CODE, validate2FARecoveryCode);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  const requestOptions = {
    method: 'POST',
    //headers: { 'Content-Type': 'application/json', ATOKEN, STOKEN },
    headers: { 'Content-Type': 'application/json', ATOKEN },
    body: JSON.stringify({ username: email, password: sha512(password) })
  };
  return await fetch(servicePath + 'account/login', requestOptions)
    .then(response => response.json())
    .then(data => data)
    .catch((error) => error);
}
const getusers = async (token) => {
  const requestOptions = {
    method: 'GET',
    //headers: { 'Content-Type': 'application/json', ATOKEN, STOKEN },
    headers: { 'Content-Type': 'application/json', ATOKEN,authorization:token },    
  };
  return await fetch(servicePath + 'user/get', requestOptions)
    .then(response => response.json())
    .then(data => data)
    .catch((error) => error);
}

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  setCurrentUser();
  setRCs([]);  
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.error) {      
      let item = 
      { 
        uid: loginUser.data._id, 
        id: loginUser.data.account_id, 
        title: loginUser.data.firstname, 
        role: 1, 
        auth_token: loginUser.data.auth_token, 
        email, 
        password: sha512(password) ,
        is_enabled_two_factor: loginUser.data.is_enabled_two_factor
      };

      if (typeof loginUser.data.remote_admin !=="undefined") {
        item["remote_admin"] = loginUser.data.remote_admin;
      }
      let users = yield call(getusers,loginUser.data.auth_token);      
      if (loginUser.data.photo) {        
        localStorage.setItem("avatar","data:image/png;base64,"+loginUser.data.photo);
      } else {
        localStorage.setItem("avatar","/assets/img/roqos/avatar.svg");
      }      

      if (loginUser.data.is_enabled_two_factor) {
        //continue in login.js
        setTempCurrentUser(item);
        setRCs(loginUser.data.rcs);        
        yield put(loginUserSuccess(item));
      }
      else {
        setCurrentUser(item);
        setTempCurrentUser();
        setRCs(loginUser.data.rcs);        
        yield put(loginUserSuccess(item));        
        if (loginUser.data.rcs && loginUser.data.rcs[0].status === 0) {
          history.push("/app/setup/activate-tutorial");
        } else {
          history.push(adminRoot);  
        }              
      }
    } 
    else {
      yield put(loginUserError(loginUser.data.message));
    }
  } 
  catch (error) {
    console.log(error);
    yield put(loginUserError(error));
  }
}

const validate2FATokenAsync = async (payload, token) => {
  const requestOptions = {
    method: 'POST',
    //headers: { 'Content-Type': 'application/json', ATOKEN, STOKEN },
    headers: { 'Content-Type': 'application/json', ATOKEN, authorization: token },
    body: JSON.stringify({ token: payload })
  };
  return await fetch(servicePath + 'account/validate2FAToken', requestOptions)
    .then(response => response.json())
    .then(data => data)
    .catch((error) => error);
}

function* validate2FAToken({ payload }) {
  
  let current_temp_user = localStorage.getItem("roqos_temp_current_user");
  current_temp_user = JSON.parse(current_temp_user);
  
  const validate2FAResult = yield call(validate2FATokenAsync, payload.code, current_temp_user.auth_token);
  
  if (validate2FAResult.error) {
    yield put(validateTwoFactorTokenError(validate2FAResult.data.message));
    return;
  }
  setCurrentUser(current_temp_user);
  setTempCurrentUser();
  yield put(validateTwoFactorTokenSuccess("success"));
  payload.history.push(adminRoot);
}

const validate2FARecoveryCodeAsync = async (payload, token) => {
  const requestOptions = {
    method: 'POST',
    //headers: { 'Content-Type': 'application/json', ATOKEN, STOKEN },
    headers: { 'Content-Type': 'application/json', ATOKEN, authorization: token },
    body: JSON.stringify({ recovery_code: payload })
  };
  return await fetch(servicePath + 'account/validate2FARecoveryCode', requestOptions)
    .then(response => response.json())
    .then(data => data)
    .catch((error) => error);
}

function* validate2FARecoveryCode({ payload }) {
  
  let current_temp_user = localStorage.getItem("roqos_temp_current_user");
  current_temp_user = JSON.parse(current_temp_user);
  
  const validate2FAResult = yield call(validate2FARecoveryCodeAsync, payload.code, current_temp_user.auth_token);
  
  if (validate2FAResult.error) {
    yield put(validateRecoveryCodeError(validate2FAResult.data.message));
    return;
  }
  setCurrentUser(current_temp_user);
  setTempCurrentUser();
  yield put(validateRecoveryCodeSuccess("success"));
  payload.history.push(adminRoot);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {    
  setCurrentUser();
  setRCs([]);
  serviceWorker.unregister();
  localStorage.clear();    
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;  
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watch2FAUser),
  ]);
}
