/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-classes-per-file */
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VALIDATE_TWO_FACTOR_TOKEN,
  VALIDATE_TWO_FACTOR_TOKEN_ERROR,
  VALIDATE_TWO_FACTOR_TOKEN_SUCCESS,
  VALIDATE_RECOVERY_CODE,
  VALIDATE_RECOVERY_CODE_SUCCESS,
  VALIDATE_RECOVERY_CODE_ERROR,
  RECOVERY_CODE_MODE,
  TWO_FACTOR_TOKEN_MODE,
} from '../actions';
import { getCurrentUser } from '../../helpers/Utils';
import { isAuthGuardActive, currentUser } from '../../constants/defaultValues';


const INIT_STATE = {
  currentUser: isAuthGuardActive ? currentUser : getCurrentUser(),
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, currentUser: action.payload, error: '' };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: '',
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: '' };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: '',
        error: '',
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: '',
        resetPasswordCode: '',
        error: action.payload.message,
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, currentUser: action.payload, error: '' };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case LOGOUT_USER:
      return { ...state, currentUser: null, error: '' };
    case VALIDATE_TWO_FACTOR_TOKEN:
      return { ...state, loading: true, error2FA: '', error2FARecovery: '' };
    case VALIDATE_TWO_FACTOR_TOKEN_SUCCESS:
      return { ...state, loading: false, error2FA: false }
    case VALIDATE_TWO_FACTOR_TOKEN_ERROR:
      return { ...state, loading: false, error2FA: action.payload.message }
    case VALIDATE_RECOVERY_CODE:
      return { ...state, loading: true, error2FA: '', error2FARecovery: '' };
    case VALIDATE_RECOVERY_CODE_SUCCESS:
      return { ...state, loading: false, error2FARecovery: false }
    case VALIDATE_RECOVERY_CODE_ERROR:
      return { ...state, loading: false, error2FARecovery: action.payload.message }
    case RECOVERY_CODE_MODE:
      return { ...state, loading: false, recoveryCodeMode: true }
    case TWO_FACTOR_TOKEN_MODE:
      return { ...state, loading: false, recoveryCodeMode: false }
    default:
      return { ...state };
  }
};
