/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-classes-per-file */
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VALIDATE_TWO_FACTOR_TOKEN,
  VALIDATE_TWO_FACTOR_TOKEN_SUCCESS,
  VALIDATE_TWO_FACTOR_TOKEN_ERROR,
  RECOVERY_CODE_MODE,
  TWO_FACTOR_TOKEN_MODE,
  VALIDATE_RECOVERY_CODE,
  VALIDATE_RECOVERY_CODE_SUCCESS,
  VALIDATE_RECOVERY_CODE_ERROR
} from '../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history },
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const validateTwoFactorToken = (code, history) => ({
  type: VALIDATE_TWO_FACTOR_TOKEN,
  payload: { code, history }
});

export const validateTwoFactorTokenSuccess = (message) => ({
  type: VALIDATE_TWO_FACTOR_TOKEN_SUCCESS,
  payload: { message }
});

export const validateTwoFactorTokenError = (message) => ({
  type: VALIDATE_TWO_FACTOR_TOKEN_ERROR,
  payload: { message }
});

export const validateRecoveryCode = (code, history) => ({
  type: VALIDATE_RECOVERY_CODE,
  payload: { code, history }
});

export const validateRecoveryCodeSuccess = (message) => ({
  type: VALIDATE_RECOVERY_CODE_SUCCESS,
  payload: { message }
});

export const validateRecoveryCodeError = (message) => ({
  type: VALIDATE_RECOVERY_CODE_ERROR,
  payload: { message }
});

export const switchToRecoveryCodeMode = (message) => ({
  type: RECOVERY_CODE_MODE,
  payload: { message }
});

export const switchToTwoFactorTokenMode = (message) => ({
  type: TWO_FACTOR_TOKEN_MODE,
  payload: { message }
});
