/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-classes-per-file */
const packageJSON = require('../../package.json');
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'de', name: 'Deutsche', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'tr', name: 'Türkçe', direction: 'ltr' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/app';
export const searchPath = `${adminRoot}/#`;
export const servicePath = packageJSON.roqosAGWLink;
export const pushWoosh = packageJSON.pushWoosh;
export const ATOKEN = packageJSON.ATOKEN;
export const STOKEN = packageJSON.STOKEN;
export const appVersion = packageJSON.version;
export const currentUser = {}
export const longestTimeout = packageJSON.longestTimeout;
export const darkCubedTermConditionLink = packageJSON.darkCubedTermConditionLink;
export const precinctTermConditionLink = packageJSON.precinctTermConditionLink;
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.blueolympic';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
