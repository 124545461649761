/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-classes-per-file */
import {
    RC_GET,
    RC_GET_ERROR,
    RC_GET_SUCCESS,
    RC_SELECT,
    RC_GET_SELECT,
    RC_GET_WAN,
    RC_GET_WAN_ERROR,
    RC_GET_WAN_SUCCESS,
    RC_POST_PORT_SETTING,
    RC_POST_PORT_SETTING_ERROR,
    RC_POST_PORT_SETTING_SUCCESS,
    GET_WAN_PASSPHRASE,
    GET_WAN_PASSPHRASE_ERROR,
    GET_WAN_PASSPHRASE_SUCCESS,
    RC_ADVANCED_GET,
    RC_ADVANCED_GET_ERROR,
    RC_ADVANCED_GET_SUCCESS,
    RC_GET_WIFI_SCHEDULING,
    RC_GET_WIFI_SCHEDULING_ERROR,
    RC_GET_WIFI_SCHEDULING_SUCCESS
} from '../actions';

// GET RC
export const getRC = () => ({
  type: RC_GET,
});

export const getRCSuccess = (item) => ({
  type: RC_GET_SUCCESS,
  payload: item,
});

export const getRCError = (error) => ({
  type: RC_GET_ERROR,
  payload: error,
});

// GET RC ADVANCED
export const getRCAdvanced = () => ({
  type: RC_ADVANCED_GET,
});

export const getRCAdvancedSuccess = (item) => ({
  type: RC_ADVANCED_GET_SUCCESS,
  payload: item,
});

export const getRCAdvancedError = (error) => ({
  type: RC_ADVANCED_GET_ERROR,
  payload: error,
});

// SELECT RC
export const selectRC = (id) => ({
  type: RC_SELECT,
  payload: id,
});

export const getRCWithselect = (id) => ({
  type: RC_GET_SELECT,
  payload: id,
});

export const getRCWAN = () => ({
  type: RC_GET_WAN,
});

export const getRCWANSuccess = (item) => ({
  type: RC_GET_WAN_SUCCESS,
  payload: item,
});

export const getRCWANError = (error) => ({
  type: RC_GET_WAN_ERROR,
  payload: error,
});


export const postRCPortSetting = (payload) => ({
  type: RC_POST_PORT_SETTING,
  payload: payload
});

export const postRCPortSettingSuccess = (data) => ({
  type: RC_POST_PORT_SETTING_SUCCESS,
  payload: data
});

export const postRCPortSettingError = (error) => ({
  type: RC_POST_PORT_SETTING_ERROR,
  payload: error,  
});


export const getWANPassphrase = (type) => ({
  type: GET_WAN_PASSPHRASE,
  payload: type
});

export const getWANPassphraseSuccess = (rassphrase) => ({
  type:GET_WAN_PASSPHRASE_SUCCESS,
  payload: rassphrase,
});

export const getWANPassphraseError = (error) => ({
  type: GET_WAN_PASSPHRASE_ERROR,
  payload: error,
});

// GET RC WIFI SCHEDULES
export const getRCWifiSchedules = () => ({
  type: RC_GET_WIFI_SCHEDULING,
});

export const getRCWifiSchedulesSuccess = (item) => ({
  type: RC_GET_WIFI_SCHEDULING_SUCCESS,
  payload: item,
});

export const getRCWifiSchedulesError = (error) => ({
  type: RC_GET_WIFI_SCHEDULING_ERROR,
  payload: error,
});