/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-classes-per-file */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { servicePath } from "../../constants/defaultValues"
import { 
    RC_GET, 
    RC_SELECT,
    RC_GET_SELECT, 
    RC_GET_WAN,    
    RC_POST_PORT_SETTING,
    GET_WAN_PASSPHRASE,    
    RC_ADVANCED_GET,
    RC_GET_WIFI_SCHEDULING,    
} from '../actions';
import {
    getRCSuccess,
    getRCError,    
    getRCWANError,
    getRCWANSuccess,    
    postRCPortSettingSuccess,
    postRCPortSettingError,    
    getWANPassphraseError,
    getWANPassphraseSuccess,    
    getRCAdvancedError,
    getRCAdvancedSuccess,    
    getRCWifiSchedulesError,    
    getRCWifiSchedulesSuccess,
} from './actions';

import {  getHeader } from '../../helpers/Utils';

//######### GET RC
const getRCRequest = async () => {
    
    const requestOptions = {
        method: 'GET',
        headers: getHeader()
    };
    return await fetch(servicePath + 'rc/get', requestOptions)
        .then(response => response.json())
        .then(data => data.data)
        .catch((error) => error);
};
function* getRCItem() {
    try {
        const response = yield call(getRCRequest);
        yield put(getRCSuccess(response));
    } catch (error) {
        yield put(getRCError(error));
    }
}
export function* watchGetRC() {
    yield takeEvery(RC_GET, getRCItem);
}

//######### GET RC Advanced
const getRCAdvancedRequest = async () => {    
    const requestOptions = {
        method: 'GET',
        headers: getHeader()
    };
    return await fetch(servicePath + 'rc/advanced', requestOptions)
        .then(response => response.json())
        .then(data => data.data)
        .catch((error) => error);
};
function* getRCAdvancedItem() {
    try {
        const response = yield call(getRCAdvancedRequest);
        yield put(getRCAdvancedSuccess(response));
    } catch (error) {
        yield put(getRCAdvancedError(error));
    }
}
export function* watchGetRCAdvanced() {
    yield takeEvery(RC_ADVANCED_GET, getRCAdvancedItem);
}



//######## Select RC
const selectRCRequest = async (id) => {
    
    const requestOptions = {
        method: 'POST',
        headers: getHeader(),
        body: JSON.stringify({ RC_id: id })
    };
    return await fetch(servicePath + 'account/select-rc', requestOptions)
        .then(response => response.json())
        .then(data => data.data)
        .catch((error) => error);
};

function* selectRCItem({ payload }) {
    try {
        const response = yield call(selectRCRequest, payload);
        yield put(getRCSuccess(response));
    } catch (error) {
        yield put(getRCError(error));
    }
}
// ###### Get rc with  Select
function* getRCWithSelect({ payload }) {
    try {
        yield call(selectRCRequest, payload);                
        const response = yield call(getRCRequest);
        yield put(getRCSuccess(response));
    } catch (error) {
        yield put(getRCError(error));
    }
}
export function* watchselectRC() {
    yield takeEvery(RC_SELECT, selectRCItem);
}

export function* watchGetRCWithselect() {
    yield takeEvery(RC_GET_SELECT, getRCWithSelect);
}


// RC GET WAN
const getRCWANRequest = async () => {
        
    const requestOptions = {
        method: 'GET',
        headers: getHeader()
    };
    return await fetch(servicePath + 'rc/advanced/port/wan', requestOptions)
        .then(response => response.json())
        .then(data => data.data)
        .catch((error) => error);
};

function* getRCWANItem() {
    try {
        const response = yield call(getRCWANRequest);
        yield put(getRCWANSuccess(response));
    } catch (error) {
        yield put(getRCWANError(error));
    }
}
export function* watchGetRCWAN() {
    yield takeEvery(RC_GET_WAN, getRCWANItem);
}

//######### GET wifiScheduling
const getwifiSchedulingRequest = async () => {    
    const requestOptions = {
        method: 'GET',
        headers: getHeader()
    };
    return await fetch(servicePath + 'wifiScheduling/wifi-scheduling', requestOptions)
        .then(response => response.json())
        .then(data => data.data)
        .catch((error) => error);
};
function* getwifiSchedulingItem() {
    try {
        const response = yield call(getwifiSchedulingRequest);
        yield put(getRCWifiSchedulesSuccess(response));
    } catch (error) {
        yield put(getRCWifiSchedulesError(error));
    }
}
export function* watchGetRCWifiSchedules() {
    yield takeEvery(RC_GET_WIFI_SCHEDULING, getwifiSchedulingItem);
}

// RC GET WAN PASSPHRASE
const getRCWANPassphrase = async (type) => {        
    const requestOptions = {
        method: 'GET',
        headers: getHeader()
    };
    return await fetch(servicePath + 'rc/passphrase/'+type, requestOptions)
        .then(response => response.json())        
        .catch((error) => error);
};

function* getRCWANPassphraseItem({ payload }) {
    try {        
        const response = yield call(getRCWANPassphrase, payload );
        if (response.error) {
            yield put(getWANPassphraseError(response.data.message));            
        } else {
            yield put(getWANPassphraseSuccess(response.data));
        }                
    } catch (error) {
        yield put(getWANPassphraseError(error));
    }
}
export function* watchGetRCWANPassphrase() {
    yield takeEvery(GET_WAN_PASSPHRASE, getRCWANPassphraseItem);
}

const postRCPortSettingRequest = async (data) => {    
    
    const requestOptions = {
        method: 'POST',
        headers: getHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(servicePath + 'rc/advanced/port/setting', requestOptions)
        .then(response => response.json())        
        .catch((error) => error);
};


function* postRCPortSettingItem({ payload }) {
    try {        
        const response = yield call(postRCPortSettingRequest, payload);        
        if (response.error) {
            yield put(postRCPortSettingError(response.data.message));            
        } else {
            yield put(postRCPortSettingSuccess(response.data));
        }        
    } catch (error) {
        yield put(postRCPortSettingError(error));
    }
}

export function* watchPostRCPortSetting() {
    yield takeEvery(RC_POST_PORT_SETTING, postRCPortSettingItem);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetRC), 
        fork(watchselectRC),
        fork(watchGetRCWithselect),
        fork(watchGetRCWAN),
        fork(watchPostRCPortSetting),
        fork(watchGetRCWANPassphrase),
        fork(watchGetRCAdvanced),
        fork(watchGetRCWifiSchedules)
    ]);
}