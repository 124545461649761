/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-classes-per-file */
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  ATOKEN, STOKEN,
  servicePath
} from '../constants/defaultValues';
import axios from 'axios';
import _ from 'lodash';
export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getArrayData = (data) => {
  let array = data;
  if (Array.isArray(array)) {
    return array[0];
  } else {
    return array;
  }
}
export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error)
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error)
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error)
    currentColor = defaultColor
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error)
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentRadius -> error", error)
    currentRadius = 'rounded'
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentRadius -> error", error)
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language = localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
      ? localStorage.getItem('currentLanguage')
      : defaultLocale;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentLanguage -> error", error)
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentLanguage -> error", error)
  }
};


export const validateEmail = (email) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
    return (true)
  return (false)
}

export const getCurrentUser = () => {
  let user = null;
  try {
    user = localStorage.getItem('roqos_current_user') != null ? JSON.parse(localStorage.getItem('roqos_current_user')) : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error)
    user = null;
  }
  return user;
}


export const getHeader = () => {
  let user = getCurrentUser();
  return { 'Content-Type': 'application/json', ATOKEN, STOKEN, authorization: user ? user.auth_token : null }
}

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('roqos_current_user', JSON.stringify(user))
    } else {
      localStorage.removeItem('roqos_current_user')
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error)
  }
};

export const setTempCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('roqos_temp_current_user', JSON.stringify(user))
    } else {
      localStorage.removeItem('roqos_temp_current_user')
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setTempCurrentUser -> error", error)
  }
};


export const getRCs = () => {
  let rcs = [];
  try {
    rcs = localStorage.getItem('rcs') != null ? JSON.parse(localStorage.getItem('rcs')) : [];
    if (rcs.length === 1) {
      if (_.isEmpty(rcs[0].SerialNum)) {
        rcs = [];
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getRcs -> error", error)
    rcs = [];
  }
  return rcs;
}

export const setRCs = (rcs) => {
  try {
    localStorage.setItem('rcs', JSON.stringify(rcs));
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setRCs -> error", error)
  }
};

export const getActiveRC = async () => {
  return await getArrayData(await roqosDataLocal().rcs.filter(e => { return e.active === true }))
}
export const checkUrl = (url) => {
  let regex = /(([A-Za-z]{3,9}):\/\/)?([-;:&=\+\$,\w]+@{1})?(([-A-Za-z0-9]+\.)+[A-Za-z]{2,3})(:\d+)?((\/[-\+~%/\.\w]+)?\/?([&?][-\+=&;%@\.\w]+)?(#[\w]+)?)?/g;
  return regex.test(url);
}

export const setActiveRC = (rc) => {
  try {
    localStorage.setItem('activeRC', JSON.stringify(rc))
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setActiveRC -> error", error)
  }
};

// Device ICON 
const rq = (name) => { return `/assets/img/devices/${name}.png` };

const roqos_images = {
  DeviceSmartphone: [rq('DeviceSmartPhoneOnline'), rq('0423-smartphone')],
  DeviceTablet: [rq('DeviceTabletOnline'), rq('0429-tablet2')],
  DeviceFax: [rq('DeviceFaxOnline'), rq('DeviceFaxOffline')],
  DeviceGaming: [rq('DeviceGamingOnline'), rq('0134-gamepad')],
  DeviceLaptop: [rq('DeviceLaptopOnline'), rq('0430-laptop')],
  DeviceDesktop: [rq('DeviceDesktopOnline'), rq('0432-desktop')],
  DevicePrinter: [rq('DevicePrinterOnline'), rq('0178-printer')],
  DeviceAugmented: [rq('DeviceAugmentedOnline'), rq('DeviceAugmentedOffline')],
  DeviceCamera: [rq('cameraonline'), rq('camera')],
  DeviceMediaPlayer: [rq('0251-media-online'), rq('0251-media')],
  DeviceSpeaker: [rq('SpeakerOnline'), rq('SpeakerOffline')],
  DeviceTelevision: [rq('DeviceTelevisionOnline'), rq('0438-tv')],
  DeviceCctv: [rq('DeviceCctvOnline'), rq('DeviceCctvOffline')],
  DeviceSecurity: [rq('DeviceSecurityOnline'), rq('0254-surveillance')],
  DeviceDoorKnob: [rq('DeviceDoorKnobOnline'), rq('DeviceDoorKnobOffline')],
  DeviceDoorbell: [rq('DeviceDoorbellOnline'), rq('DeviceDoorbellOffline')],
  DeviceWatch: [rq('watchonline'), rq('watch')],
  DeviceStationaryBike: [rq('Stationary-bikeOnline'), rq('Stationary-bikeOffline')],
  DeviceBalance: [rq('balanceonline'), rq('balance')],
  DeviceCoffeeMachine: [rq('DeviceCoffeeMachineOnline'), rq('DeviceCoffeeMachineOffline')],
  DeviceBulb: [rq('bulbonline'), rq('bulb')],
  DeviceSocket: [rq('SocketOnline'), rq('SocketOffline')],
  DeviceThermometer: [rq('DeviceThermometerOnline'), rq('DeviceThermometerOffline')],
  DeviceSmokeAlarm: [rq('SmokeAlarmOnline'), rq('SmokeAlarmOffline')],
  DeviceSportsCar: [rq('Sports-carOnline'), rq('Sports-carOffline')],
  DeviceGateway: [rq('DeviceGatewayOnline'), rq('0172-hdd')],
  DeviceServer: [rq('DeviceServerOnline'), rq('0103-server')],
  DeviceLan: [rq('DeviceLanOnline'), rq('0694-lan')],
  DeviceApple: [rq('DeviceAppleOnline'), rq('iOS-Apple-icon')],
  DeviceAndroid: [rq('DeviceAndroidOnline'), rq('Android-icon')],
  DeviceRC: [rq('DeviceRCOnline'), rq('DeviceRCOffline')],
  DeviceCloud: [rq('CloudOnline'), rq('CloudOffline')],
}
const UnknownOnline = rq('DeviceUnknownOnline');
const UnknownOffline = rq('0856-question-circle');

export const deviceImage = (type, isOnline) => {
  isOnline = (isOnline || '').toUpperCase() == 'ONLINE';
  const [online = UnknownOnline, offline = UnknownOffline] = roqos_images[type] || [];
  return isOnline ? online : offline;
}

export const getAllDeviceIcons = () => {
  return roqos_images
}
export const getAllDeviceIconsCustum = () => {
  return {
    DeviceSpeaker: [rq('SpeakerOnline'), rq('SpeakerOffline')],
    DeviceDoorbell: [rq('DeviceDoorbellOnline'), rq('DeviceDoorbellOffline')],
    DeviceDoorKnob: [rq('DeviceDoorKnobOnline'), rq('DeviceDoorKnobOffline')],

    DeviceCoffeeMachine: [rq('DeviceCoffeeMachineOnline'), rq('DeviceCoffeeMachineOffline')],


    DeviceSocket: [rq('SocketOnline'), rq('SocketOffline')],
    DeviceSportsCar: [rq('Sports-carOnline'), rq('Sports-carOffline')],


    DeviceStationaryBike: [rq('Stationary-bikeOnline'), rq('Stationary-bikeOffline')],

    DeviceBalance: [rq('balanceonline'), rq('balance')],
    DeviceRC: [rq('DeviceRCOnline'), rq('DeviceRCOffline')],
    DeviceApple: [rq('DeviceAppleOnline'), rq('iOS-Apple-icon')],
    DeviceAndroid: [rq('DeviceAndroidOnline'), rq('Android-icon')],
    DeviceCloud: [rq('CloudOnline'), rq('CloudOffline')],
  }
}

export const getDeviceFontIcons = () => {
  let icons = [
    'iconsminds-smartphone-4',
    'iconsminds-tablet-3',
    'iconsminds-telephone-2',
    'iconsminds-gamepad-2',
    'iconsminds-laptop-3',
    'iconsminds-computer',
    rq('0178-printer'),
    'simple-icon-camera',
    'iconsminds-video',
    'iconsminds-tv',
    'iconsminds-webcam',
    'iconsminds-security-camera',
    'iconsminds-watch',
    'iconsminds-idea',
    'iconsminds-plug-in',
    'iconsminds-temperature-2',
    'iconsminds-car',
    rq('0103-server'),
    'simple-icon-organization',
    'iconsminds-remote-controll-2',
    rq('SpeakerOffline'),
    rq('DeviceDoorbellOffline'),
    rq('DeviceDoorKnobOffline'),
    rq('DeviceCoffeeMachineOffline'),
    rq('SocketOffline'),
    rq('Sports-carOffline'),
    rq('Stationary-bikeOffline'),
    rq('balance'),
    rq('DeviceRCOffline'),
    rq('iOS-Apple-icon'),
    rq('Android-icon'),
    rq('CloudOffline'),
    'icon-hotel-restaurant-204',
    'icon-medical-008',
    'icon-communication-035',
    'icon-education-035',
    'icon-sport-028',
    'icon-music-021',
    'icon-weather-029',
  ];
  return icons;
}

export const iconFromDeviceType = (deviceType) => {
  switch (deviceType) {
    case "iPhone":
      return "DeviceApple"
    case "iPad":
      return "DeviceApple"
    case "Laptop":
      return "DeviceLaptop"
    case "MacBook":
      return "DeviceLaptop"
    case "Android Device":
      return "DeviceAndroid"
    case "Apple Device":
      return "DeviceApple"
    case "Virtual Machine":
      return "DeviceDesktop"
    case "RoqosController":
      return "DeviceRC"
    case "SmokeAlarm":
      return "DeviceSmokeAlarm"
    case "RC10":
      return "DeviceRC"
    default:
      return "DeviceUnknown"
  }
}

export const isRC10AP = (state) => {
  if (state.rc.HWVersion) {
    return state.rc.HWVersion.includes("RC10-AP");
  }
  return false;
}

export const isRC10L2 = (state) => {
  if (state.rc.HWVersion) {
    return state.rc.HWVersion.includes("RC10-L2") || state.rc.HWVersion.includes("RC20-ML2") || state.rc.HWVersion.includes("RC10-ML2");
  }
  return false;
}

export const isDebian8 = (state) => {
  if (state.rc.HWVersion) {
    return (state.rc.HWVersion.includes("RC10") || state.rc.HWVersion.includes("RC100")) && !state.rc.HWVersion.includes("-");
  }
  return false;
}

export const isL2L3Convertible = (state) => {
  if (state.rc.HWVersion) {
    return state.rc.HWVersion.includes("RC10-M") || state.rc.HWVersion.includes("RC20-M") || state.rc.HWVersion.includes("RC10-ML2") || state.rc.HWVersion.includes("RC20-ML2")
  }
  return false;
}
// returns if this device is convertible between Layer 2 and Layer 3 modes
export const isRC10WithMetalChasis = (state, HWVersion = undefined) => {
  let rcsWithMetalChasis = ["RC10-L2", "RC10-M", "RC10-ML2", "RC10-PT", "RC20-M", "RC20-ML2", "RC20-PT"];
  if (HWVersion !== undefined) {
    return rcsWithMetalChasis.includes(HWVersion.split(" ")[0]);
  }
  if (state.rc && state.rc.HWVersion) {
    return rcsWithMetalChasis.includes(state.rc.HWVersion.split(" ")[0]);
  }
  return false;
}

export const isWiFiSupported = (state) => {
  let rcsWithoutWiFi = ["RC10-L2", "RC10-ML2", "RC10-NCW", "RC20-ML2", "RC100-NCW", "RC150-NCW", "RC1000"];
  if (state.rc && state.rc.HWVersion) {
    return !rcsWithoutWiFi.includes(state.rc.HWVersion.split(" ")[0]);
  }
  return true;
}

export const isWiFiSupportedfromHWVersion = (HWVersion) => {
  let rcsWithoutWiFi = ["RC10-L2", "RC10-ML2", "RC10-NCW", "RC20-ML2", "RC100-NCW", "RC100-WP", "RC150-NCW", "RC1000"];
  if (HWVersion) {
    return !rcsWithoutWiFi.includes(HWVersion.split(" ")[0]);
  }
  return true;
}

export const isCellularSupported = (state) => {
  let rcsWithoutCellular = ["RC10-AP", "RC10-L2", "RC10-ML2", "RC10-NCW", "RC20-ML2", "RC100-NCW", "RC150-NCW", "RC1000"];
  if (state.rc && state.rc.HWVersion) {
    return !rcsWithoutCellular.includes(state.rc.HWVersion.split(" ")[0]);
  }
  return true;
}

export const getRCType = (SerialNum) => {
  if (SerialNum && (SerialNum.indexOf('RC105') > -1 || SerialNum.indexOf('RC125') > -1)) {
    return 'rcHundred';
  }
  else if (SerialNum && (SerialNum.indexOf('RC150') > -1)) {
    return 'rcHundredFifty';
  }
  else if (SerialNum && (SerialNum.indexOf('RC1000') > -1) && SerialNum.length == 12) {
    return 'rcThousand';
  }
  else {
    return 'rcTen'
  }
}

export const getRCImage = (SerialNum, color = null, explicitModel = null) => {
  console.log(explicitModel);
  if (explicitModel && explicitModel.includes("-M")) {
    return '/assets/img/roqos/RC10-M_RC20-M.png'
  }
  if (explicitModel==="TealRC10-P") {
    return '/assets/img/roqos/teal_rc.png'
  }
  if (explicitModel && explicitModel.includes("-L2")) {
    return '/assets/img/roqos/RC10-L2.png'
  }
  if (explicitModel && explicitModel.includes("RC150")) {
    return '/assets/img/roqos/RC150_Power.png';
  }
  if (explicitModel && explicitModel.includes("RC105")) {
    return '/assets/img/roqos/RC100_angle_view_1000px_shadow.png';
  }
  if (explicitModel && /^(RC10-AP|RC10-L2|RC10-R2|RC20)/.test(explicitModel)) {
    if (color && ['ruby', 'teal', 'coal', 'cream'].indexOf(color.toLowerCase()) > -1) {
      return '/assets/img/roqos/' + color.toLowerCase() + '_rc1.png'
    } else {
      return "/assets/img/roqos/teal_rc1.png";
    }
  }

  if (SerialNum && (SerialNum.indexOf('RC1000') > -1) && SerialNum.length == 12) {
    return '/assets/img/roqos/RC1000.png'
  }
  else if (SerialNum && (SerialNum.indexOf('RC150') > -1)) {
    return '/assets/img/roqos/RC150_Power.png';
  }
  else if (SerialNum && (SerialNum.indexOf('RC105') > -1 || SerialNum.indexOf('RC125') > -1)) {
    return '/assets/img/roqos/RC100_angle_view_1000px_shadow.png';
  } else {
    if (color && ['ruby', 'teal', 'coal', 'cream'].indexOf(color.toLowerCase()) > -1) {
      return '/assets/img/roqos/' + color.toLowerCase() + '_rc1.png'
    } else {
      return "/assets/img/roqos/teal_rc1.png";
    }
  }
}

export const getTimeZoneList = () => {
  return require('./timezonelist.json');
}

export const getCountryBlockList = () => {
  return require('./countryblocklist.json');
}

export const getcountryList = () => {
  return require('./countryList.json');
}

export const getStates = () => {
  return require('./states.json');
}

export const getCanadaStates = () => {
  return require('./canadaStates.json');
}

export const roqosData = () => {
  return require('./roqosData.json');
}
export const roqosDataLocal = () => {
  return JSON.parse(localStorage.getItem("roqosData"));
}

export const setroqosDataLocal = async () => {
  localStorage.setItem("roqosData", await JSON.stringify(roqosData()));
}


export const getDefaultRC = (type, SerialNum) => {
  let rcs = roqosDataLocal().rcs
  let id = parseInt(rcs[rcs.length - 1].id) + 1;

  let rc = {
    "RCName": SerialNum,
    "_id": id,
    "id": id,
    "active": true,
    "account_id": 1,
    "status": 4,
    "created": new Date(),
    "SerialNum": SerialNum,
    "HWVersion": type + " Rev1.0 09/16/2019",
    "WiFi": true,
    "SSIDName": ["WifiName"],
    "WiFiNWMode": "Mixed",
    "VPNService": true,
    "VPNUsername": "vpnuser",
    "SWVersion": "2.3.1 9/13/2021",
    "block_internet": false,
    "block_internet_schedule": "",
    "block_wifi": false,
    "block_wifi_schedule": "",
    "color": "Coal",
    "split5G": false,
    "guestWiFi": {
      "enabled": false,
      "name": "Guest",
      "security": "temporaryCode",
      "tempCode": null,
      "passphrase": null,
      "duration": null
    },
    "WPS": {
      "status": "Disabled",
      "lastDeviceName": "",
      "lastDeviceMAC": "",
      "lastConnectedAt": ""
    },
    "filter": {
      "category": [
        "Cybersecurity Protection"
      ],
      "perUser": []
    },
    "2ndSSID": {
      "enabled": false,
      "ssid": "roqos-2ndWifi",
      "passphrase": null
    },
    "lastModified": "2021-09-15T01:00:20.617Z",
    "lastOffline": "2021-09-14T15:13:06.693Z",
    "local_web_console": {
      "always_on": false
    },
    "isTMobile": false,
    "online": true,
    "onlineSince": "2021-09-14T15:17:42.406Z",
    "publicIP": "88.247.171.82",
    "GeoIP": {
      "city": "Washington",
      "country": "ABD",
      "isp": "Washington",
      "state": "Washington",
      "infoType": "internalModule",
      "lastUpdate": "2021-09-11T03:58:13.000Z",
      "latitude": 38.88279970877885,
      "longitude": -77.02919423396753,
      "now": "2021-09-12T09:58:09.164Z"
    },
    "rcbindzonesVer": "0.20200709.0",
    "rcrulesVer": "0.20210813.0",
    "cellular_data_plan": {
      "limit": 0,
      "cycle_start_day": 1,
      "_id": 0,
      "packageLimit": 0,
      "customizedLimit": null,
      "startDate": "2021-08-12T11:40:30.423Z",
      "isSubscriptionActive": true,
      "customerOwnedSIM": true
    },
    "witfooLicense": {
      "precinctOrganizationName": "Demo",
      "precinctNumberOfWorkers": 1,
      "termOfServiceAsYear": 1,
      "startDate": "2021-08-12T11:40:30.630Z"
    },
    "Passphrase": null,
    "WiFiFrequency": "2.4G",
    "MACs": [
      {
        "interface": "br0",
        "HWaddress": "a8:c8:7f:d0:00:75"
      },
      {
        "interface": "br1",
        "HWaddress": "a8:c8:7f:e0:00:75"
      },
      {
        "interface": "eth0",
        "HWaddress": "a8:c8:7f:50:00:75"
      },
      {
        "interface": "eth1",
        "HWaddress": "a8:c8:7f:60:00:75"
      },
      {
        "interface": "eth2",
        "HWaddress": "a8:c8:7f:70:00:75"
      },
      {
        "interface": "eth3",
        "HWaddress": "a8:c8:7f:80:00:75"
      },
      {
        "interface": "eth4",
        "HWaddress": "a8:c8:7f:90:00:75"
      },
      {
        "interface": "eth5",
        "HWaddress": "a8:c8:7f:a0:00:75"
      },
      {
        "interface": "eth6",
        "HWaddress": "a8:c8:7f:b0:00:75"
      },
      {
        "interface": "eth7",
        "HWaddress": "a8:c8:7f:c0:00:75"
      },
      {
        "interface": "wlan_wan",
        "HWaddress": "a8:c8:7f:d1:00:75"
      },
      {
        "interface": "wlan0",
        "HWaddress": "a8:c8:7f:d0:00:75"
      }
    ],
    "user": [
      {
        "_id": "611508a9b3f1937037b05409",
        "account_id": 1577107354459,
        "RC_id": [
          "611508a9b3f1937037b05406"
        ],
        "name": "Demo",
        "bonusTime": 0,
        "administrator": true,
        "is_owner": true,
        "guest": false,
        "photo_ref": "611508a9b3f1937037b05408",
        "notification": {
          "push": true,
          "sms": false,
          "email": false
        },
        "filter": {
          "category": [],
          "custom": [],
          "whitelist": []
        },
        "lastModified": "2021-08-19T09:13:58.776Z"
      },
      {
        "_id": "611508adb3f1937037b0540b",
        "account_id": 1577107354459,
        "RC_id": [
          "611508a9b3f1937037b05406"
        ],
        "name": "Unassigned Devices",
        "bonusTime": 0,
        "administrator": false,
        "is_default": true,
        "is_owner": false,
        "guest": false,
        "photo_ref": null,
        "blockTraffic": false,
        "blockBedTime": false,
        "blockLightOut": false,
        "blockTrafficData": null,
        "blockBedTimeData": null,
        "blockLightOutData": null,
        "inappropriate_content": "allow",
        "notification": {
          "push": true,
          "sms": false,
          "email": false
        },
        "filter": {
          "custom": [],
          "category": [],
          "whitelist": []
        }
      },
      {
        "_id": "612e6026276cf032a0019b21",
        "account_id": 1577107354459,
        "RC_id": [
          "611508a9b3f1937037b05406"
        ],
        "name": "Adam Smith",
        "bonusTime": 0,
        "administrator": false,
        "is_owner": false,
        "guest": false,
        "photo_ref": "612e6026276cf032a0019b20",
        "notification": {
          "push": true,
          "sms": false,
          "email": false
        },
        "filter": {
          "blockAll": null,
          "category": [],
          "custom": [],
          "whitelist": []
        },
        "lastModified": "2021-09-06T14:32:19.785Z"
      }
    ],
    "advanced": {
      "blockedCountry": [],
      "blockedCountryEnabled": false,
      "blockedCountryNotification": true,
      "account_id": 1,
      "isVPNAllowedRC": true,
      "internetIP": {
        "DHCPClient": true,
        "IPaddress": "192.168.81.110",
        "subnetMask": "255.255.255.0",
        "defaultGateway": "192.168.81.1",
        "automaticDNS": true,
        "primary": "192.168.81.1",
        "secondary": "",
        "PPPoE": false,
        "password": "",
        "username": ""
      },
      "localNetwork": {
        "localIP": "192.168.23.1",
        "subnetMask": "255.255.255.0",
        "DHCPServer": true,
        "startIPaddress": "192.168.23.100",
        "endIPaddress": "192.168.23.250",
        "staticIPAssignments": []
      },
      "portForwarding": [],
      "UPnP": true,
      "roqosLogoLight": true,
      "timeZone": "Europe/Istanbul",
      "VPN": {
        "enabled": false,
        "Hostname": "RC150500075.roqosdns.com",
        "Port": "5052",
        "Protocol": "UDP",
        "NetworkAccess": [
          "home",
          "internet"
        ],
        "CACertificate": ""
      },
      "VPNClient": {
        "connectTo": "Another_Roqos_Core",
        "provider": {
          "Roqos_VPN": {
            "server": "default_US-East"
          },
          "PureVPN": {
            "server": "US-SanFrancisco-TCP"
          },
          "Another_Roqos_Core": {
            "RC_id": "2",
            "RC_type": "RC20 Rev4.2 2020/09/25",
            "guest": false,
            "server": "My RC 20 "
          }
        },
        "active": {},
        "reason": "sigusr",
        "connected": false,
        "serverIP": "",
        "enabled": false,
        "killSwitch": false,
        "includeAll": false
      },
      "lastModified": "2021-09-14T15:17:42.927Z",
      "usageDeviceData": {
        "enabled": false
      },
      "dynamicDNS": {
        "Enabled": true,
        "Name": "mustafa55",
        "Zone": "roqosdns.com",
        "ThirdParty": false,
        "Provider": "dynDNS",
        "Username": "demo@roqos.com",
        "Password": "",
        "Domain": "mustafa55.roqosdns.com"
      },
      "VPN_guest": {
        "enabled": true,
        "Port": "5053",
        "Protocol": "UDP",
        "NetworkAccess": [
          "internet"
        ],
        "CACertificate": "",
        "tlsCrypt": "",
        "users": [
          {
            "email": "test@roqos.com",
            "name": "Test User",
            "block": false,
            "status": "completed"
          }
        ]
      },
      "usageData": {
        "enabled": false
      }
    },
    "interface": [
      {
        "_id": "611508add80ad28a17ba3526",
        "RC_id": "611508a9b3f1937037b05406",
        "interface": "eth0",
        "DHCPClient": true,
        "IPaddress": "192.168.81.110",
        "PPPoE": false,
        "active": true,
        "automaticDNS": true,
        "bridge": "",
        "defaultGateway": "192.168.81.1",
        "enabled": true,
        "floating": true,
        "hardware": "RJ45",
        "lastModified": "2021-09-14T15:17:42.930Z",
        "legend": "Port 1",
        "name": "WAN 1",
        "password": "",
        "primary": "192.168.81.1",
        "priority": 1,
        "role": "WAN",
        "secondary": "",
        "subnetMask": "255.255.255.0",
        "type": "wired",
        "username": "",
        "plugged": true
      },
      {
        "_id": "611508c1d80ad28a17ba365c",
        "RC_id": "611508a9b3f1937037b05406",
        "interface": "br0",
        "HWaddress": "a8:c8:7f:d0:00:75",
        "active": false,
        "bridge": "",
        "enabled": true,
        "floating": false,
        "hardware": "Virtual",
        "legend": "Bridge 1",
        "name": "Main Network",
        "priority": 99,
        "role": "bridge",
        "type": "bridge"
      },
      {
        "_id": "611508c1d80ad28a17ba365d",
        "RC_id": "611508a9b3f1937037b05406",
        "interface": "br1",
        "HWaddress": "a8:c8:7f:e0:00:75",
        "active": false,
        "bridge": "",
        "enabled": true,
        "floating": false,
        "hardware": "Virtual",
        "legend": "Bridge 2",
        "name": "Guest Network",
        "priority": 99,
        "role": "bridge",
        "type": "bridge"
      },
      {
        "_id": "611508c1d80ad28a17ba365e",
        "RC_id": "611508a9b3f1937037b05406",
        "interface": "eth1",
        "DHCPClient": true,
        "HWaddress": "a8:c8:7f:60:00:75",
        "active": false,
        "automaticDNS": true,
        "bridge": "br0",
        "enabled": true,
        "floating": true,
        "hardware": "RJ45",
        "legend": "Port 2",
        "name": "LAN 1",
        "priority": 2,
        "role": "LAN",
        "type": "wired"
      },
      {
        "_id": "611508c1d80ad28a17ba3665",
        "RC_id": "611508a9b3f1937037b05406",
        "interface": "wlan0",
        "HWaddress": "a8:c8:7f:d0:00:75",
        "active": false,
        "bridge": "br0",
        "enabled": true,
        "floating": false,
        "hardware": "AC",
        "legend": "WiFi 1",
        "name": "Wireless LAN",
        "priority": 99,
        "role": "LAN",
        "type": "wifi"
      },
      {
        "_id": "611508c1d80ad28a17ba3666",
        "RC_id": "611508a9b3f1937037b05406",
        "interface": "wlan0_1",
        "HWaddress": "",
        "active": false,
        "bridge": "br1",
        "enabled": true,
        "floating": false,
        "hardware": "Virtual",
        "legend": "WiFi 1-1",
        "name": "Guest Wireless LAN",
        "priority": 99,
        "role": "Guest",
        "type": "wifi"
      },
      {
        "_id": "6131fd2bd80ad28a17f3e7c1",
        "RC_id": "611508a9b3f1937037b05406",
        "interface": "wlan_wan",
        "DHCPClient": true,
        "HWaddress": "a8:c8:7f:d1:00:75",
        "IPaddress": "",
        "PPPoE": false,
        "SSID": "",
        "active": false,
        "automaticDNS": true,
        "bridge": "",
        "defaultGateway": "",
        "enabled": false,
        "floating": false,
        "hardware": "Virtual",
        "legend": "WiFi as WAN",
        "name": "WiFi as WAN",
        "newNetwork": false,
        "password": "",
        "primary": "",
        "priority": 99,
        "role": "WAN",
        "secondary": "",
        "subnetMask": "",
        "type": "wireless",
        "username": ""
      },
      {
        "_id": "613ef245d80ad28a1708877f",
        "RC_id": "611508a9b3f1937037b05406",
        "interface": "wwan0",
        "DHCPClient": true,
        "HWaddress": "2e:dd:cb:3b:6e:c0",
        "IPaddress": "10.57.119.196",
        "active": false,
        "automaticDNS": true,
        "bitErrorRate": "not known or not detectable",
        "bridge": "",
        "defaultGateway": "10.57.119.197",
        "enabled": true,
        "firmware": "SWI9X30C_02.30.01.01 r7792 CARMD-EV-FRMWR2 2018/07/13 02:24:52\r\n\r\nOK\r\n",
        "floating": false,
        "gnss": "Lat: 38 Deg 10 Min 23.76 Sec N\nLon: 27 Deg 21 Min 16.25 Sec E\nTime: 2021 09 15 2 12:20:17",
        "hardware": "",
        "iccid": "8937204016201308108",
        "imei": "359073060202169",
        "imsi": "248010420130810",
        "initialized": true,
        "internal": true,
        "lastModified": "2021-09-15T12:20:05.328Z",
        "lastReset": 1631706645.743876,
        "legend": "Cellular 1",
        "mode": "WCDMA",
        "name": "Cellular 1",
        "operator": "Turk Telekom",
        "plugged": true,
        "primary": "192.98.49.8",
        "priority": 9,
        "role": "WAN",
        "secondary": "192.98.49.9",
        "signalQuality": "-79 dBm",
        "subnetMask": "255.255.255.248",
        "supportedSIM": true,
        "temperature": "46",
        "type": "cellular"
      }
    ],
    "blockedcountry": [
      {
        "_id": "611b91a2d80ad28a17c75d39",
        "RC_id": "611508a9b3f1937037b05406",
        "alert_only": false,
        "country": [
          "TR",
          "FR"
        ],
        "enabled": false,
        "lastModified": "2021-09-08T12:37:02.881Z",
        "notification": true
      }
    ],
    "devices": [
      {
        "_id": "611d43031dce2c557bf34c10",
        "HWaddress": "ae:d5:12:1c:e5:f0",
        "Interface": "br0",
        "AgeingTimer": "0.98",
        "Status": "Online",
        "Hostname": "iPhone 13 Pro",
        "guest": false,
        "Manufacturer": "Apple",
        "DeviceType": "Phone",
        "ConnectedTo": "wlan0",
        "ConnType": "2.4GHz",
        "SourceRCHostname": "Apple Device",
        "Static": false,
        "IPaddress": "192.168.23.198",
        "DeviceOS": "IOS",
        "Model": "13 Pro",
        "Since": "2021-08-18T17:36:01.892009Z",
        "Upload": true,
        "UpCount": 0,
        "NewDevice": true,
        "event_type": "Add",
        "bypassVPN": true,
        "priority": "Low",
        "RC_id": "611508a9b3f1937037b05406",
        "assigned": true,
        "userId": "611508adb3f1937037b0540b",
        "userName": "Demo",
        "lastModified": "2021-09-14T09:54:11.974Z",
        "new": false,
        "icon": "iconsminds-smartphone-4",
        "operatingsystem": "IOS"
      },
      {
        "_id": "611bbf0e8bb31cb2fdbcfa36",
        "HWaddress": "30:35:ad:dd:7f:82",
        "Interface": "br0",
        "AgeingTimer": "0.09",
        "Status": "Online",
        "Hostname": "MacBook Pro",
        "guest": false,
        "Manufacturer": "Apple",
        "DeviceType": "Apple Device",
        "ConnectedTo": "wlan0",
        "ConnType": "2.4GHz",
        "SourceRCHostname": "RC150500075",
        "Static": false,
        "IPaddress": "192.168.23.118",
        "DeviceOS": "",
        "Model": "",
        "Since": "2021-08-18T01:20:31.901096Z",
        "Upload": true,
        "UpCount": 0,
        "NewDevice": true,
        "event_type": "Add",
        "bypassVPN": true,
        "priority": "Low",
        "RC_id": "611508a9b3f1937037b05406",
        "assigned": true,
        "userId": "611508adb3f1937037b0540b",
        "userName": "Unassigned Devices",
        "lastModified": "2021-09-14T09:54:11.974Z",
        "lastVisited": {
          "timestamp": "2021-08-17T14:48:00.000Z",
          "url": "apple.com"
        },
        "new": false,
        "icon": "iconsminds-laptop-3",
        "operatingsystem": "MACOS"
      },
      {
        "_id": "611bba9a8bb31cb2fdbced12",
        "HWaddress": "9c:e6:5e:48:95:56",
        "Interface": "br0",
        "AgeingTimer": "0.07",
        "Status": "Offline",
        "Hostname": "iPhone",
        "guest": false,
        "Manufacturer": "Apple",
        "DeviceType": "iPhone",
        "ConnectedTo": "wlan0",
        "ConnType": "2.4GHz",
        "SourceRCHostname": "RC150500075",
        "Static": false,
        "IPaddress": "192.168.23.175",
        "DeviceOS": "",
        "Model": null,
        "Since": "2021-08-31T20:48:52.182227Z",
        "Upload": true,
        "UpCount": 0,
        "NewDevice": true,
        "event_type": "Add",
        "bypassVPN": true,
        "priority": "Low",
        "RC_id": "611508a9b3f1937037b05406",
        "assigned": true,
        "userId": "611508adb3f1937037b0540b",
        "userName": "Unassigned Devices",
        "lastModified": "2021-09-14T09:54:11.974Z",
        "lastVisited": {
          "timestamp": "2021-08-17T14:08:00.000Z",
          "url": "lencr.org"
        },
        "icon": "iconsminds-smartphone-4",
        "operatingsystem": "IOS",
        "new": false
      }
    ],
    "notification": [
      {
        "_id": "6140bd113e8b0d5ed8e00016",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-14T15:17:37.916Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/14/21 6:17PM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "6140bc663e8b0d5ed8e00015",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-14T15:13:06.693Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/14/21 6:13PM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "61405d450fbebd5653e6ff05",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-14T08:28:53.716Z",
          "title": "Your Roqos appliance restarted.",
          "bodyTS": "Your Roqos appliance with serial number RC150500075 restarted at 9/14/21 11:28AM.",
          "body": "Your Roqos appliance with serial number RC150500075 restarted.",
          "bodyPush": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_de": "Your Roqos appliance restarted.",
          "body_de": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_es": "Your Roqos appliance restarted.",
          "body_es": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_tr": "Your Roqos appliance restarted.",
          "body_tr": "Your Roqos appliance with serial number RC150500075 restarted."
        },
        "event_type": "systembootup",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "61405d430fbebd5653e6ff04",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-14T08:28:51.047Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/14/21 11:28AM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613fb003c3f74a40f18e280e",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-13T20:07:59.281Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/13/21 11:07PM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613ef2071e02fe69280cbeeb",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-13T06:39:03.391Z",
          "title": "Your Roqos appliance restarted.",
          "bodyTS": "Your Roqos appliance with serial number RC150500075 restarted at 9/13/21 9:39AM.",
          "body": "Your Roqos appliance with serial number RC150500075 restarted.",
          "bodyPush": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_de": "Your Roqos appliance restarted.",
          "body_de": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_es": "Your Roqos appliance restarted.",
          "body_es": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_tr": "Your Roqos appliance restarted.",
          "body_tr": "Your Roqos appliance with serial number RC150500075 restarted."
        },
        "event_type": "systembootup",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613ef2051e02fe69280cbeea",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-13T06:39:01.499Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/13/21 9:39AM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613bf1a41e02fe69280cbe72",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-10T23:58:56.738Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/11/21 2:58AM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613b7a081e02fe69280cbe62",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "user": {
          "id": "611508adb3f1937037b0540b",
          "name": "Unassigned Devices"
        },
        "device": {
          "id": "611b7d24e0f2aed8ec1383c2",
          "name": "Unknown",
          "ip": "192.168.23.163"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-10T15:30:05.950Z",
          "title": "Security Threat Blocked",
          "bodyTS": "Suspicious internet activity on device named Unknown assigned to profile Unassigned Devices has been detected and blocked at 6:30PM on 9/10/21.",
          "body": "Suspicious internet activity on device named Unknown assigned to profile Unassigned Devices has been detected and blocked.",
          "bodyPush": "Suspicious internet activity on device named Unknown assigned to profile Unassigned Devices has been detected and blocked.",
          "title_de": "Sicherheitsgefahr geblockt",
          "body_de": "Es wurden verdächtige Internetaktivitäten auf Unknown, welches dem Profil Nicht zugewiesene Geräte zugewiesen ist, erkannt und gesperrt.",
          "title_es": "Amenaza de Seguridad Bloqueada",
          "body_es": "Se ha detectado y bloqueado la actividad sospechosa de Internet en el dispositivo Unknown asignado al perfil Dispositivos sin Designar.",
          "title_tr": "Güvenlik Tehdidi Bloke Edildi",
          "body_tr": "Atanmamış Cihazlar profiline ait Unknown adlı cihazda şüpheli internet aktivitesi bloke edildi."
        },
        "event_type": "alert",
        "src_ip": "192.168.23.163",
        "src_port": 48420,
        "dest_ip": "192.168.23.1",
        "dest_port": 53,
        "proto": "UDP",
        "signature_id": 2033721,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613b79cb1e02fe69280cbe61",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "user": {
          "id": "611508adb3f1937037b0540b",
          "name": "Unassigned Devices"
        },
        "device": {
          "id": "611b7d24e0f2aed8ec1383c2",
          "name": "Unknown",
          "ip": "192.168.23.163"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-10T15:29:08.700Z",
          "title": "Security Threat Blocked",
          "bodyTS": "Suspicious internet activity on device named Unknown assigned to profile Unassigned Devices has been detected and blocked at 6:29PM on 9/10/21.",
          "body": "Suspicious internet activity on device named Unknown assigned to profile Unassigned Devices has been detected and blocked.",
          "bodyPush": "Suspicious internet activity on device named Unknown assigned to profile Unassigned Devices has been detected and blocked.",
          "title_de": "Sicherheitsgefahr geblockt",
          "body_de": "Es wurden verdächtige Internetaktivitäten auf Unknown, welches dem Profil Nicht zugewiesene Geräte zugewiesen ist, erkannt und gesperrt.",
          "title_es": "Amenaza de Seguridad Bloqueada",
          "body_es": "Se ha detectado y bloqueado la actividad sospechosa de Internet en el dispositivo Unknown asignado al perfil Dispositivos sin Designar.",
          "title_tr": "Güvenlik Tehdidi Bloke Edildi",
          "body_tr": "Atanmamış Cihazlar profiline ait Unknown adlı cihazda şüpheli internet aktivitesi bloke edildi."
        },
        "event_type": "alert",
        "src_ip": "192.168.23.163",
        "src_port": 48420,
        "dest_ip": "192.168.23.1",
        "dest_port": 53,
        "proto": "UDP",
        "signature_id": 2033721,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613b79b81e02fe69280cbe60",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "user": {
          "id": "611508adb3f1937037b0540b",
          "name": "Unassigned Devices"
        },
        "device": {
          "id": "611b7d24e0f2aed8ec1383c2",
          "name": "Unknown",
          "ip": "192.168.23.163"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-10T15:28:48.298Z",
          "title": "Security Threat Blocked",
          "bodyTS": "Suspicious internet activity on device named Unknown assigned to profile Unassigned Devices has been detected and blocked at 6:28PM on 9/10/21.",
          "body": "Suspicious internet activity on device named Unknown assigned to profile Unassigned Devices has been detected and blocked.",
          "bodyPush": "Suspicious internet activity on device named Unknown assigned to profile Unassigned Devices has been detected and blocked.",
          "title_de": "Sicherheitsgefahr geblockt",
          "body_de": "Es wurden verdächtige Internetaktivitäten auf Unknown, welches dem Profil Nicht zugewiesene Geräte zugewiesen ist, erkannt und gesperrt.",
          "title_es": "Amenaza de Seguridad Bloqueada",
          "body_es": "Se ha detectado y bloqueado la actividad sospechosa de Internet en el dispositivo Unknown asignado al perfil Dispositivos sin Designar.",
          "title_tr": "Güvenlik Tehdidi Bloke Edildi",
          "body_tr": "Atanmamış Cihazlar profiline ait Unknown adlı cihazda şüpheli internet aktivitesi bloke edildi."
        },
        "event_type": "alert",
        "src_ip": "192.168.23.163",
        "src_port": 48420,
        "dest_ip": "192.168.23.1",
        "dest_port": 53,
        "proto": "UDP",
        "signature_id": 2033721,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613b79361e02fe69280cbe5f",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-10T15:26:46.330Z",
          "title": "Your Roqos appliance restarted.",
          "bodyTS": "Your Roqos appliance with serial number RC150500075 restarted at 9/10/21 6:26PM.",
          "body": "Your Roqos appliance with serial number RC150500075 restarted.",
          "bodyPush": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_de": "Your Roqos appliance restarted.",
          "body_de": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_es": "Your Roqos appliance restarted.",
          "body_es": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_tr": "Your Roqos appliance restarted.",
          "body_tr": "Your Roqos appliance with serial number RC150500075 restarted."
        },
        "event_type": "systembootup",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613b79341e02fe69280cbe5e",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252",
              "DD287-75F89_d11d5988-20cc-4fdc-8fa7-d52bde5b37c9",
              "DD287-75F89_eba9f83b-9b9f-44c1-849a-f7102d53896d",
              "DD287-75F89_a763feae-19f5-4ebc-97bb-97d5084a8b4d",
              "DD287-75F89_5b381112-2b88-4cee-88b6-9125bd7441c0"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-10T15:26:44.729Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/10/21 6:26PM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613b789c1e02fe69280cbe5d",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252",
              "DD287-75F89_d11d5988-20cc-4fdc-8fa7-d52bde5b37c9",
              "DD287-75F89_eba9f83b-9b9f-44c1-849a-f7102d53896d",
              "DD287-75F89_a763feae-19f5-4ebc-97bb-97d5084a8b4d",
              "DD287-75F89_ec85856d-df49-4f1e-ae49-7cedb96a1c7c",
              "DD287-75F89_e3e6313a-eb71-48e3-acc0-3c5858cb034a",
              "DD287-75F89_5b381112-2b88-4cee-88b6-9125bd7441c0",
              "DD287-75F89_2cffa1aa-76b9-4505-9a45-49a54d31b95a"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-10T15:22:32.436Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/10/21 6:22PM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613b1b6ed2792e1595d92c8d",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-10T08:46:38.910Z",
          "title": "LTE Module Warning",
          "bodyTS": "Your Roqos Core named RC150500075 and the serial number RC150500075 is unable to detect a cellular signal on 9/10/21 at 11:46AM. Please make sure your unit's location is good enough to get cellular signal. Otherwise please power cycle your unit to eliminate this rare issue. If this problem continues, please contact Roqos Customer Support at support@roqos.com.",
          "body": "Your Roqos Core named RC150500075 and the serial number RC150500075 is unable to detect a cellular signal. Please make sure your unit's location is good enough to get cellular signal. Otherwise please power cycle your unit to eliminate this rare issue. If this problem continues, please contact Roqos Customer Support at support@roqos.com.",
          "bodyPush": "Your Roqos Core named RC150500075 and the serial number RC150500075 is unable to detect a cellular signal. Please make sure your unit's location is good enough to get cellular signal. Otherwise please power cycle your unit to eliminate this rare issue. If this problem continues, please contact Roqos Customer Support at support@roqos.com.",
          "title_de": "LTE Module Warning",
          "body_de": "Your Roqos Core named RC150500075 and the serial number RC150500075 is unable to detect a cellular signal. Please make sure your unit's location is good enough to get cellular signal. Otherwise please power cycle your unit to eliminate this rare issue. If this problem continues, please contact Roqos Customer Support at support@roqos.com.",
          "title_es": "LTE Module Warning",
          "body_es": "Your Roqos Core named RC150500075 and the serial number RC150500075 is unable to detect a cellular signal. Please make sure your unit's location is good enough to get cellular signal. Otherwise please power cycle your unit to eliminate this rare issue. If this problem continues, please contact Roqos Customer Support at support@roqos.com.",
          "title_tr": "LTE Module Warning",
          "body_tr": "Your Roqos Core named RC150500075 and the serial number RC150500075 is unable to detect a cellular signal. Please make sure your unit's location is good enough to get cellular signal. Otherwise please power cycle your unit to eliminate this rare issue. If this problem continues, please contact Roqos Customer Support at support@roqos.com."
        },
        "event_type": "rcWANRedundancy",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613b1b42d2792e1595d92c8c",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-10T08:45:54.464Z",
          "title": "Your Roqos appliance restarted.",
          "bodyTS": "Your Roqos appliance with serial number RC150500075 restarted at 9/10/21 11:45AM.",
          "body": "Your Roqos appliance with serial number RC150500075 restarted.",
          "bodyPush": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_de": "Your Roqos appliance restarted.",
          "body_de": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_es": "Your Roqos appliance restarted.",
          "body_es": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_tr": "Your Roqos appliance restarted.",
          "body_tr": "Your Roqos appliance with serial number RC150500075 restarted."
        },
        "event_type": "systembootup",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613b1b40d2792e1595d92c8b",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-10T08:45:52.528Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/10/21 11:45AM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613a8556d2792e1595d92c7d",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49",
              "DD287-75F89_8111baf9-fb16-468f-8b5c-e19ef859ebd7"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-09T22:04:34.292Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/10/21 1:04AM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "6138110cd2792e1595d92c47",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-08T01:25:32.176Z",
          "title": "LTE Module Warning",
          "bodyTS": "Your Roqos Core named RC150500075 and the serial number RC150500075 did not detect an LTE module on 9/8/21 at 4:25AM. Please power cycle your unit to detect it. If this problem continues, please contact Roqos Customer Support at support@roqos.com.",
          "body": "Your Roqos Core named RC150500075 and the serial number RC150500075 did not detect an LTE module. Please power cycle your unit to detect it. If this problem continues, please contact Roqos Customer Support at support@roqos.com.",
          "bodyPush": "Your Roqos Core named RC150500075 and the serial number RC150500075 did not detect an LTE module. Please power cycle your unit to detect it. If this problem continues, please contact Roqos Customer Support at support@roqos.com.",
          "title_de": "LTE Module Warning",
          "body_de": "Your Roqos Core named RC150500075 and the serial number RC150500075 did not detect an LTE module. Please power cycle your unit to detect it. If this problem continues, please contact Roqos Customer Support at support@roqos.com.",
          "title_es": "LTE Module Warning",
          "body_es": "Your Roqos Core named RC150500075 and the serial number RC150500075 did not detect an LTE module. Please power cycle your unit to detect it. If this problem continues, please contact Roqos Customer Support at support@roqos.com.",
          "title_tr": "LTE Module Warning",
          "body_tr": "RC150500075 adlı ve RC150500075 seri numaralı Roqos Core'unuz LTE modülünü algılamadı. Lütfen sorunun tespit edilmesi için ünitenizi yeniden başlatın. Bu sorun devam ederse, lütfen support@roqos.com adresinden Roqos Müşteri Desteği ile iletişime geçin."
        },
        "event_type": "rcWANRedundancy",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613810fed2792e1595d92c46",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-08T01:25:18.266Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/8/21 4:25AM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "61380305d2792e1595d92c44",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-08T00:24:01.444Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/8/21 3:24AM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "6137d0c7d2792e1595d92c43",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-07T20:51:19.706Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/7/21 11:51PM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "6137cef1d2792e1595d92c42",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-07T20:41:49.650Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/7/21 11:41PM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "61379218d2792e1595d92c40",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-07T16:23:52.939Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/7/21 7:23PM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613791ddd2792e1595d92c3f",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-07T16:21:13.337Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/7/21 7:21PM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "6135ca96082e5865d9e1e559",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-06T08:00:22.143Z",
          "title": "Your Roqos appliance restarted.",
          "bodyTS": "Your Roqos appliance with serial number RC150500075 restarted at 9/6/21 11:00AM.",
          "body": "Your Roqos appliance with serial number RC150500075 restarted.",
          "bodyPush": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_de": "Your Roqos appliance restarted.",
          "body_de": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_es": "Your Roqos appliance restarted.",
          "body_es": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_tr": "Your Roqos appliance restarted.",
          "body_tr": "Your Roqos appliance with serial number RC150500075 restarted."
        },
        "event_type": "systembootup",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "6135ca92082e5865d9e1e558",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-06T08:00:18.798Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/6/21 11:00AM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613226a9082e5865d9e1e52e",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T13:42:29.272Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/3/21 4:42PM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613225a7082e5865d9e1e52d",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T13:39:51.745Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/3/21 4:39PM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613225a5082e5865d9e1e52c",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T13:39:49.745Z",
          "title": "Your Roqos appliance restarted.",
          "bodyTS": "Your Roqos appliance with serial number RC150500075 restarted at 9/3/21 4:39PM.",
          "body": "Your Roqos appliance with serial number RC150500075 restarted.",
          "bodyPush": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_de": "Your Roqos appliance restarted.",
          "body_de": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_es": "Your Roqos appliance restarted.",
          "body_es": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_tr": "Your Roqos appliance restarted.",
          "body_tr": "Your Roqos appliance with serial number RC150500075 restarted."
        },
        "event_type": "systembootup",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "613224bd082e5865d9e1e52b",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T13:34:17.813Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/3/21 4:34PM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "61321f2e082e5865d9e1e52a",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T13:12:14.260Z",
          "title": "Your Roqos appliance restarted.",
          "bodyTS": "Your Roqos appliance with serial number RC150500075 restarted at 9/3/21 4:12PM.",
          "body": "Your Roqos appliance with serial number RC150500075 restarted.",
          "bodyPush": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_de": "Your Roqos appliance restarted.",
          "body_de": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_es": "Your Roqos appliance restarted.",
          "body_es": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_tr": "Your Roqos appliance restarted.",
          "body_tr": "Your Roqos appliance with serial number RC150500075 restarted."
        },
        "event_type": "systembootup",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "61321f2c082e5865d9e1e529",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T13:12:12.241Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/3/21 4:12PM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "61321e25082e5865d9e1e528",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T13:06:09.902Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/3/21 4:06PM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "61321c6e082e5865d9e1e527",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T13:00:30.089Z",
          "title": "Your Roqos appliance restarted.",
          "bodyTS": "Your Roqos appliance with serial number RC150500075 restarted at 9/3/21 4:00PM.",
          "body": "Your Roqos appliance with serial number RC150500075 restarted.",
          "bodyPush": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_de": "Your Roqos appliance restarted.",
          "body_de": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_es": "Your Roqos appliance restarted.",
          "body_es": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_tr": "Your Roqos appliance restarted.",
          "body_tr": "Your Roqos appliance with serial number RC150500075 restarted."
        },
        "event_type": "systembootup",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "61321c6c082e5865d9e1e526",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T13:00:28.376Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/3/21 4:00PM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "61321ae2082e5865d9e1e525",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T12:52:14.594Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/3/21 3:52PM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "6131f6db082e5865d9e1e51f",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T10:20:11.770Z",
          "title": "Your Roqos appliance restarted.",
          "bodyTS": "Your Roqos appliance with serial number RC150500075 restarted at 9/3/21 1:20PM.",
          "body": "Your Roqos appliance with serial number RC150500075 restarted.",
          "bodyPush": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_de": "Your Roqos appliance restarted.",
          "body_de": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_es": "Your Roqos appliance restarted.",
          "body_es": "Your Roqos appliance with serial number RC150500075 restarted.",
          "title_tr": "Your Roqos appliance restarted.",
          "body_tr": "Your Roqos appliance with serial number RC150500075 restarted."
        },
        "event_type": "systembootup",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "6131f6d9082e5865d9e1e51e",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-03T10:20:09.878Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/3/21 1:20PM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "61300713082e5865d9e1e4f3",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-01T23:03:11.576Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/2/21 2:03AM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "612fecb6082e5865d9e1e4f1",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-01T21:12:22.048Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/2/21 12:12AM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "612fec98082e5865d9e1e4f0",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-01T21:10:12.459Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/2/21 12:10AM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "612fec6e082e5865d9e1e4ef",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-01T21:11:10.594Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/2/21 12:11AM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "612fec26082e5865d9e1e4ee",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-01T21:08:18.534Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/2/21 12:08AM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "612fd780082e5865d9e1e4eb",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-01T19:41:52.912Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/1/21 10:41PM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "612fd757082e5865d9e1e4ea",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-01T19:39:31.227Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/1/21 10:39PM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "612f76ea38f8cc5e901a8e80",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "user": {
          "id": "612e6026276cf032a0019b21",
          "name": "tester"
        },
        "device": {
          "id": "6115376ad2af4d1dfa1c8d60",
          "name": "M2007J20CG-POCOX3NFC",
          "ip": "192.168.23.239"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-01T12:49:36.414Z",
          "title": "Security Threat Blocked",
          "bodyTS": "Suspicious communication attempt between M2007J20CG-POCOX3NFC (tcp/39802) and <a href='https://www.abuseipdb.com/check/65.9.73.48'>65.9.73.48</a> (tcp/80) has been blocked on RC150500075 at 3:49PM on 9/1/21.",
          "body": "Suspicious communication attempt between M2007J20CG-POCOX3NFC (tcp/39802) and <a href='https://www.abuseipdb.com/check/65.9.73.48'>65.9.73.48</a> (tcp/80) has been blocked.",
          "bodyPush": "Suspicious communication attempt between M2007J20CG-POCOX3NFC (tcp/39802) and 65.9.73.48 (tcp/80) has been blocked on RC150500075.",
          "body_de": " Es wurde ein verdächtiger Kommunikationsversuch zwischen M2007J20CG-POCOX3NFC (tcp/39802) und <a href='https://www.abuseipdb.com/check/65.9.73.48'>65.9.73.48</a> (tcp/80) gesperrt.",
          "title_de": "Sicherheitsgefahr geblockt",
          "body_es": "Se ha bloqueado el intento de comunicación sospechoso entre M2007J20CG-POCOX3NFC (tcp/39802) y <a href='https://www.abuseipdb.com/check/65.9.73.48'>65.9.73.48</a> (tcp/80).",
          "title_es": "Amenaza de Seguridad Bloqueada",
          "body_tr": "M2007J20CG-POCOX3NFC (tcp/39802) adlı cihaz ile <a href='https://www.abuseipdb.com/check/65.9.73.48'>65.9.73.48</a> (tcp/80) arasında şüpheli internet aktivitesi bloke edildi.",
          "title_tr": "Güvenlik Tehdidi Bloke Edildi"
        },
        "event_type": "alert",
        "src_ip": "192.168.23.239",
        "src_port": 39802,
        "dest_ip": "65.9.73.48",
        "dest_port": 80,
        "proto": "TCP",
        "signature_id": 2100498,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "612f75f038f8cc5e901a8e7f",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "user": {
          "id": "612e6026276cf032a0019b21",
          "name": "tester"
        },
        "device": {
          "id": "6115376ad2af4d1dfa1c8d60",
          "name": "M2007J20CG-POCOX3NFC",
          "ip": "192.168.23.239"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-09-01T12:45:34.641Z",
          "title": "Access to Server in Turkey Blocked",
          "bodyTS": "Communication attempt between M2007J20CG-POCOX3NFC  (tcp/48248)  and a server in Turkey  (94.55.118.66, tcp/443)  has been blocked on RC150500075.",
          "body": "Communication attempt between M2007J20CG-POCOX3NFC  (tcp/48248)  and a server in Turkey  (94.55.118.66, tcp/443)  has been blocked on RC150500075.",
          "bodyPush": "Communication between M2007J20CG-POCOX3NFC and a server in Turkey has been blocked on RC150500075.",
          "title_de": "Zugang auf Server in Türkei gesperrt",
          "body_de": "Ein Kommunikationsversuch zwischen M2007J20CG-POCOX3NFC  (tcp/48248)  und einem Server in Türkei  (94.55.118.66, tcp/443)  wurde auf RC150500075 gesperrt.",
          "title_es": "Acceso al servidor en Turquía Bloqueado",
          "body_es": "El intento de comunicación entre M2007J20CG-POCOX3NFC  (tcp/48248)  y un servidor en Turquía  (94.55.118.66, tcp/443)  se ha bloqueado en RC150500075.",
          "title_tr": "Türkiye İsimli Ülkedeki Sunucuya Erişim Bloke Edildi",
          "body_tr": "RC150500075 isimli Roqos Core'a bağlı M2007J20CG-POCOX3NFC  (tcp/48248)  isimli cihaz ile Türkiye isimli ülkedeki sunucunun  (94.55.118.66, tcp/443)  arasındaki bağlantı bloke edilmiştir."
        },
        "event_type": "alert",
        "src_ip": "192.168.23.239",
        "src_port": 48248,
        "dest_ip": "94.55.118.66",
        "dest_port": 443,
        "proto": "TCP",
        "signature_id": 1000000,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "612ea875f03ad3497ff2c837",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-08-31T22:08:53.919Z",
          "title": "Internet Connection Restored",
          "bodyTS": "Internet connection has been restored on RC150500075 at 9/1/21 1:08AM.",
          "body": "Internet connection has been restored on RC150500075.",
          "bodyPush": "Internet connection has been restored on RC150500075.",
          "title_de": "Internetverbindung wiederhergestellt",
          "body_de": "Die Internetverbindung wurde auf RC150500075 wiederhergestellt.",
          "title_es": "Conexión a Internet Restaurada",
          "body_es": "La conexión a Internet ha sido restaurada en RC150500075.",
          "title_tr": "İnternet Bağlantısı Geri Geldi",
          "body_tr": "RC150500075 isimli Roqos Core internete tekrar bağlandı."
        },
        "event_type": "connect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      },
      {
        "_id": "612ea869f03ad3497ff2c836",
        "RC_id": "611508a9b3f1937037b05406",
        "RC_Name": "RC150500075",
        "RC_Serial_Num": "RC150500075",
        "RC_block_new_device": null,
        "notification": {
          "sms": false,
          "push": true,
          "email": false,
          "consolidate": false,
          "connection": false,
          "countryBlockNotification": false,
          "suppressCellularAlert": false
        },
        "login": {
          "id": "5e00bf9a1cd10d36844c4aec",
          "firstname": "Demo",
          "lastname": "Account",
          "username": "demo.2",
          "email": "demo@roqos.com",
          "mobile": "5394949158",
          "pushToken": {
            "DD287-75F89": [
              "dWsg82AFYnI:APA91bGycMWt9djn8PyVS8mxDb9QV4sMFd5RauPyR0RYE2j--qzY5fRRkcth-NSvdjA143EfJalrkSs7r3hNCt6jMQ_7z2GhBw35cw1HaZUvQukHxn0mKwIge8vEjOB13sJ2aJii1rfj",
              "czz1Ztrd2hs:APA91bEGMafOEG6VIZBobCXDUxviMafbtz68oqhjZmR5UJGHa1Rlkur2o1tA3DYhmA6eF7pbAH7xO1udcxrGEVnV11wxcPXuVAxoTtkZsOdFbCNb5GkQttYgrecMDwJiNzFF7HDEdjXC",
              "DD287-75F89_5bab337a-25a8-4bb5-b688-cb29a15ca756",
              "DD287-75F89_5dbf9bd5-1550-4c1f-8da3-0e03b7508f5a",
              "DD287-75F89_9f387e86-ea4f-4fce-b215-81fb7632841c",
              "DD287-75F89_8514ca7b-211e-40e4-bd76-942a90b40252",
              "DD287-75F89_4f1704a5-5e8f-4d7e-b584-89b6c6b12a49"
            ],
            "52F3C-E9EC4": []
          },
          "partner": "roqos",
          "language": "English"
        },
        "rcTimeZone": "Europe/Istanbul",
        "killSwitch": false,
        "message": {
          "timestamp": "2021-08-31T22:07:01.702Z",
          "title": "Internet Connection Lost",
          "bodyTS": "RC150500075 has lost connectivity to internet at 9/1/21 1:07AM. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "body": "RC150500075 has lost connectivity to internet. Please contact your Internet Service Provider if the problem persists for more than a few minutes.",
          "bodyPush": "RC150500075 has lost connectivity to internet.",
          "title_de": "Verbindung zum Internet getrennt",
          "body_de": "RC150500075 wurde vom Internet getrennt. Bitte kontaktieren Sie Ihren Provider, sollte das Problem für mehr als ein paar Minuten fortbestehen.",
          "title_es": "Conexión Perdida",
          "body_es": "RC150500075 ha perdido la conectividad a internet. Póngase en contacto con su proveedor de servicios de Internet si el problema persiste durante más de unos minutos.",
          "title_tr": "İnternet Bağlantısı Koptu",
          "body_tr": "RC150500075 isimli Roqos Core'un internet bağlantısı koptu. Eğer problem birkaç dakikadan fazla devam ederse lütfen internet servis sağlayıcınız ile temasa geçin."
        },
        "event_type": "disconnect",
        "src_ip": null,
        "src_port": null,
        "dest_ip": null,
        "dest_port": null,
        "proto": null,
        "signature_id": null,
        "RoqosVPNOutServer": "default_US-East",
        "src_hostname": "-",
        "VPN_client_login": "-",
        "VPN_server_type": "-",
        "publicIP": "-",
        "VPN_client_location": "-",
        "isVPNDevice": null
      }
    ],
    "vpnIN": [],
    "wan": [
      {
        "_id": "613ef245d80ad28a1708877f",
        "RC_id": "611508a9b3f1937037b05406",
        "interface": "wwan0",
        "DHCPClient": true,
        "HWaddress": "2e:dd:cb:3b:6e:c0",
        "IPaddress": "10.57.119.196",
        "active": false,
        "automaticDNS": true,
        "bitErrorRate": "not known or not detectable",
        "bridge": "",
        "defaultGateway": "10.57.119.197",
        "enabled": true,
        "firmware": "SWI9X30C_02.30.01.01 r7792 CARMD-EV-FRMWR2 2018/07/13 02:24:52\r\n\r\nOK\r\n",
        "floating": false,
        "gnss": "Lat: 38 Deg 10 Min 23.65 Sec N\nLon: 27 Deg 21 Min 16.17 Sec E\nTime: 2021 09 18 5 23:52:54",
        "hardware": "",
        "iccid": "8937204016201308108",
        "imei": "359073060202169",
        "imsi": "248010420130810",
        "initialized": true,
        "internal": true,
        "lastModified": "2021-09-20T09:20:06.052Z",
        "lastReset": 1632128896.813592,
        "legend": "Cellular 1",
        "mode": "WCDMA",
        "name": "Cellular 1",
        "operator": "Turk Telekom",
        "plugged": true,
        "primary": "192.98.49.8",
        "priority": 9,
        "role": "WAN",
        "secondary": "192.98.49.9",
        "signalQuality": "-79 dBm",
        "subnetMask": "255.255.255.248",
        "supportedSIM": true,
        "temperature": "45",
        "type": "cellular"
      },
      {
        "_id": "611508add80ad28a17ba3526",
        "RC_id": "611508a9b3f1937037b05406",
        "interface": "eth0",
        "DHCPClient": true,
        "IPaddress": "192.168.81.110",
        "PPPoE": false,
        "active": true,
        "automaticDNS": true,
        "bridge": "",
        "defaultGateway": "192.168.81.1",
        "enabled": true,
        "floating": true,
        "hardware": "RJ45",
        "lastModified": "2021-09-20T08:52:26.807Z",
        "legend": "Port 1",
        "name": "WAN 1",
        "password": "",
        "primary": "192.168.81.1",
        "priority": 1,
        "role": "WAN",
        "secondary": "",
        "subnetMask": "255.255.255.0",
        "type": "wired",
        "username": "",
        "plugged": true
      },
      {
        "_id": "6131fd2bd80ad28a17f3e7c1",
        "RC_id": "611508a9b3f1937037b05406",
        "interface": "wlan_wan",
        "DHCPClient": true,
        "HWaddress": "a8:c8:7f:d1:00:75",
        "IPaddress": "",
        "PPPoE": false,
        "SSID": "",
        "active": false,
        "automaticDNS": true,
        "bridge": "",
        "defaultGateway": "",
        "enabled": false,
        "floating": false,
        "hardware": "Virtual",
        "legend": "WiFi as WAN",
        "name": "WiFi as WAN",
        "newNetwork": false,
        "password": "",
        "primary": "",
        "priority": 99,
        "role": "WAN",
        "secondary": "",
        "subnetMask": "",
        "type": "wireless",
        "username": ""
      }
    ],
    "category": [
      {
        "_id": "58a52263c26ac90a83bf18e2",
        "type": "category",
        "name": "Ad Blocker",
        "description": "Blocks advertisements on websites served from major advertising platforms.",
        "scope": "RC"
      },
      {
        "_id": "589baad0705076cc46ed9d5f",
        "type": "category",
        "name": "Country Block",
        "description": "This feature allows you block Internet traffic to and from certain countries to better protect your home from Internet threats. Please note that some websites and apps might be using servers in other countries. Enabling this feature may block some portions of those websites or apps.",
        "scope": "RC"
      },
      {
        "_id": "589b717b40faa9f6187d23eb",
        "type": "category",
        "name": "Cybersecurity Protection",
        "description": "Protects all your connected devices against malware and online attacks. It analyzes the content of your network traffic against signatures of known attacks and blocks suspicious traffic by using a technology called Deep Packet Inspection.",
        "scope": "RC"
      }
    ],
    "vpnConnection": [],
    "countryBlock": [
      {
        "country": "China",
        "count": 14
      },
      {
        "country": "Russia",
        "count": 10
      }
    ],
    "totalTreats": 3,
    "totalInternetLost": 5,
    "totalSecurityUpdates": 0,
    "totalNewDevices": 5,
    "totalDeviceConnected": 7,
    "totalKideMode": 0,
    "totalVPNin": 6,
    "totalVPNguest": 0,
    "visiteList": [
      {
        "site": "facebook.com",
        "count": 19
      },
      {
        "site": "twitter.com",
        "count": 16
      },
      {
        "site": "cnn.com",
        "count": 10
      }
    ],
    "blockList": [
      {
        "site": "site1.com",
        "count": 14
      },
      {
        "site": "site2.com",
        "count": 11
      },
      {
        "site": "site3.com",
        "count": 9
      },
      {
        "site": "site4.com",
        "count": 6
      },
      {
        "site": "site5.com",
        "count": 1
      }
    ],
    "topSecurity": [
      {
        "device": "Device 1",
        "count": 3
      },
      {
        "device": "Device 2",
        "count": 8
      },
      {
        "device": "Device 3",
        "count": 11
      }
    ]
  }

  return rc;
}